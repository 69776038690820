.player {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px 0; */
    /* width: 250px; */
    /* aspect-ratio: 1/1; */
    width: 100%;
    height: 100%;
    background: #333;
    /* background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%); */
    /* border-radius: 20px; */
    overflow: hidden;
}

.player:hover .controls {
    visibility: visible;
}

.song {
    user-select: none;
    margin: 0 20px;
}

.controls {
    /* visibility: hidden; */
    flex-grow: 1;
    /* margin: 0 20px; */
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    transition: visibility 0.5s linear, opacity 0.5s linear;
}

.controls .basic-controls {
    display: flex;
}

.player__button,
.player__button_sub {
    width: fit-content;
    margin-bottom: 15px;
    background-color: transparent;
    border: none;
}

.player__button:focus,
.player__button_sub:focus {
    outline: none;
}

.player__button:hover,
.player__button_sub:hover {
    cursor: pointer;
}

.player__button svg {
    width: 40px;
    height: 40px;
    fill: #fff;
    filter: drop-shadow(0 0 0.75rem #000);
}

.player__button_sub svg {
    width: 25px;
    height: 25px;
    fill: #fff;
    filter: drop-shadow(0 0 0.75rem #000);
}


.player__button:hover svg,
.player__button_sub:hover svg {
    fill: var(--primary);
    filter: drop-shadow(0 0 0.75rem #fff);
}





/* .player__button svg {
    font-size: 4em;
    color: white;
} */

.bar {
    user-select: none;
    width: 100%;
    /* display: flex;
    align-items: center; */
    background: rgba(0, 0, 0, 0.6);
    padding-top: 5px;
    padding-bottom: 5px;
}

.bar .timer-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.bar__time {
    color: white;
    font-size: 11px;
}

.bar__progress {
    flex: 1;
    border-radius: 5px;
    margin: 0 20px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bar__progress__knob {
    position: relative;
    height: 10px;
    width: 10px;
    border: 1.5px solid white;
    border-radius: 50%;
    background-color: var(--primary);
}