.live-streaming-sec {
  margin-top: 48px;
  margin-bottom: 4em;
}
.live-streaming-banner-sec {
  width: 100%;
  background-position: right !important;
  background-size: cover !important;
  position: relative;
  height: 40em;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  padding: 0 2em;
}

.live-streaming-banner-sec::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.live-streaming-banner-content h3 {
  color: var(--secondary-color);
  font-size: 2em;
  margin-bottom: 0.5em;
}

.live-streaming-banner-content p {
  color: var(--secondary-color);
  font-size: 1.4em;
  font-weight: 500;
}

.new-go-live-btn {
  border: 1px solid var(--primary-color);
  padding: 7px 30px;
  font-size: 1.4em;
  color: var(--secondary-color);
  font-weight: 500;
  background: var(--primary-color);
  border-radius: 5px;
  transition: opacity 0.15s ease, background-color 0.15s ease,
    box-shadow 0.15s ease;
  display: flex;
  align-items: center;
  gap: 0.5em;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.new-go-live-btn-icon {
  width: 1.3em;
}

.new-go-live-btn:hover {
  color: var(--secondary-color) !important;
}

.new-live-history-btn {
  border: 1px solid var(--primary-color);
  padding: 7px 30px;
  font-size: 1.4em;
  color: #222222;
  font-weight: 500;
  background: var(--secondary-color);
  border-radius: 5px;
  transition: opacity 0.15s ease, background-color 0.15s ease,
    box-shadow 0.15s ease;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.new-live-history-btn:hover {
  background: var(--primary-color);
  color: var(--secondary-color) !important;
}

.live-streaming-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1em;
}

.live-streaming-header-1-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
}

.live-streaming-left-sec {
  display: flex;
  align-items: center;
  gap: 1em;
}

.live-streaming-right-sec .new-profile-store-btn-sec select {
  padding: 7px 12px;
}

.live-streaming-right-sec .new-explore-search-card {
  padding: 0;
}

.live-streaming-right-sec .new-explore-search-card .form-control {
  background-color: transparent;
}

.most-popular-header-sec {
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.most-popular-header-sec h4 {
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  position: relative;
}

.most-popular-header-sec a {
  font-size: 15px;
  font-weight: 500;
  color: var(--quaternary-color);
}

.most-popular-live-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 1em;
  padding: 1em 2em;
}

.most-popular-thumbnail-img {
  height: 25em;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.most-popular-list-sec {
  position: absolute;
  top: 20px;
  right: 20px;
}

.most-popular-list-sec ul {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.most-popular-live-card {
  position: relative;
}

.most-popular-live-card::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.25); */
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  position: absolute;
  border-radius: 10px;
}

.live-list-count {
  width: 3.5em;
  height: 3.5em;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
  padding: 18px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.live-list-img {
  width: 3.5em;
  height: 3.5em;
  object-fit: cover;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.most-popular-list-sec ul li {
  margin-top: 0;
}

.most-popular-list-sec ul li.total-count {
  position: absolute;
  right: 72px;
  top: -3px;
  z-index: 4;
}

.most-popular-list-sec ul li.user-1 {
  right: 50px;
  top: 0;
  position: absolute;
  z-index: 3;
}

.most-popular-list-sec ul li.user-2 {
  right: 28px;
  top: 0;
  position: absolute;
  z-index: 2;
}

.most-popular-list-sec ul li.user-3 {
  right: 5px;
  top: 0;
  position: absolute;
  z-index: 1;
}

.most-popular-user-info-card {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1em 1em;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.most-popular-user-img {
  height: 4em;
  width: 4em;
  object-fit: cover;
  border-radius: 50%;
}

.most-popular-user-details h4 {
  font-size: 1em;
  font-weight: 500;
  display: flex;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-bottom: 0;
  color: var(--secondary-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
}

.most-popular-user-name span {
  color: var(--secondary-color);
  font-size: 0.75em;
}

.most-popular-user-details h4 span {
  margin-left: 0.2em;
}

.most-popular-user-info {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.most-popular-user-details a {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--secondary-color);
}

.most-popular-user-btn-sec .join-now-btn {
  color: #fff;
  border: 0;
  font-size: 1.2em;
  padding: 5px 15px;
  font-weight: 500;
  border-radius: 5px;
  background-color: var(--primary-color) !important;
}

.most-popular-user-btn-sec .join-now-btn:hover,
.most-popular-user-btn-sec .join-now-btn:focus,
.most-popular-user-btn-sec .join-now-btn:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.most-popular-product-info {
  position: absolute;
  bottom: 84px;
  left: 15px;
}

.most-popular-product-info h3 {
  font-size: 1.5em;
  color: var(--secondary-color);
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
}

.most-popular-lock-sec {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  z-index: 9;
}

.most-popular-lock-icon {
  max-width: 4em;
}

.most-popular-lock-sec h2 {
  font-size: 1.8em;
  color: var(--secondary-color);
  font-weight: 600;
}

/* Live Streaming Free User */

.live-streaming-free-user-sec {
  margin-top: 48px;
  /* margin-bottom: 4em; */
  overflow-x: hidden;
}

.live-streaming-user-box {
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  width: 100%;
}

.live-streaming-user-card-left {
  width: 70%;
  padding-left: 4em;
  padding-right: 2em;
  padding-bottom: 4em;
}

.live-streaming-user-card-right {
  width: 30%;
  background-color: #edf0f6;
  padding: 2em;
}

.live-streaming-user-details h4 {
  font-size: 1.5em;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.live-streaming-user-details h3 {
  font-size: 1.7em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.live-streaming-user-action-btn-sec ul {
  display: flex;
  align-items: center;
  gap: 1em;
}

.live-streaming-user-action-btn-sec ul li {
  margin-top: 0;
}

.live-streaming-header-info {
  display: flex;
  align-items: center;
  gap: 1em;
}

.live-streaming-dropdown .dropdown-item {
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
}

.live-streaming-dropdown .dropdown-toggle {
  border: 0;
  padding: 0 !important;
  font-size: 1.4em;
  color: #222222;
  font-weight: 500;
  background: var(--secondary-color);
  border-radius: 50%;
  transition: opacity 0.15s ease, background-color 0.15s ease,
    box-shadow 0.15s ease;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.live-streaming-dropdown .dropdown-toggle:hover,
.live-streaming-dropdown .dropdown-toggle:focus,
.live-streaming-dropdown .dropdown-toggle:active {
  border: 0 !important;
  font-size: 1.4em;
  color: #222222;
  background: var(--secondary-color) !important;
  box-shadow: none !important;
  outline: none !important;
}

.show > .btn-success.dropdown-toggle {
  background-color: var(--secondary-color) !important;
}
.live-streaming-dropdown .dropdown-menu.show {
  min-width: 160px;
  transform: translate(-130px, 35px) !important;
}

.live-streaming-dropdown-icon {
  width: 3em;
}

.live-streaming-desc p {
  font-size: 1.3em;
  color: var(--quaternary-color);
  font-weight: 500;
}

.live-streaming-user-img-sec .live-streaming-user-img {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  object-fit: cover;
}

.live-streaming-live-notify-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.live-streaming-count .join-now-btn {
  color: #fff;
  border: 0;
  font-size: 1.2em;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 5px;
  background-color: var(--primary-color) !important;
}

.live-streaming-count .join-now-btn:hover,
.live-streaming-count .join-now-btn:focus,
.live-streaming-count .join-now-btn:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.live-streaming-count {
  display: flex;
  align-items: center;
  gap: 1em;
}

.live-streaming-count p {
  font-size: 1.5em;
  color: var(--tertiary-color);
  margin-bottom: 0;
  font-weight: 500;
}

.live-streaming-started-info h5 {
  font-size: 1.5em;
  color: var(--tertiary-color);
  margin-bottom: 0;
  font-weight: 500;
}

.live-streaming-started-info h5 span {
  color: var(--primary-color);
  font-weight: 600;
}

.live-streaming-card {
  position: relative;
  margin: 2em 0;
  min-height: 440px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-streaming-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.live-streaming-card::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(0, 0, 0); */
  /* background: linear-gradient(289.54deg, #000000 0.31%, rgba(0, 0, 0, 0) 19.22%); */
  position: absolute;
  border-radius: 10px;
}

.live-streaming-bg-img-sec {
  height: 43em;
}

.live-streaming-full-bg-img-sec {
  height: 100%;
}

.live-streaming-bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.live-streaming-close-btn-sec {
  position: absolute;
  top: 15px;
  right: 15px;
}

.agora-container {
  height: 44em;
  width: 100%;
}

.agora-container-full {
  height: 100% !important;
}

.close-btn-icon {
  width: 1em;
}

.live-streaming-close-btn-sec .close-btn {
  color: #fff;
  border: 0;
  font-size: 1.2em;
  letter-spacing: 0.8px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 0.5em;
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.live-streaming-close-btn-sec .close-btn:hover,
.live-streaming-close-btn-sec .close-btn:focus,
.live-streaming-close-btn-sec .close-btn:active {
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: #fff !important;
}

.live-streaming-send-tip-sec {
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.live-streaming-send-tip-icon {
  max-width: 9em;
}

.live-streaming-send-tip-sec .send-tip-btn {
  border: 1px solid var(--secondary-color) !important;
  padding: 5px 15px !important;
  font-size: 1.2em;
  letter-spacing: 0.8px;
  color: var(--secondary-color);
  font-weight: 500;
  background: transparent !important;
  border-radius: 25px;
  transition: opacity 0.15s ease, background-color 0.15s ease,
    box-shadow 0.15s ease;
  display: inline-block;
}

.live-streaming-send-tip-sec .send-tip-btn:hover,
.live-streaming-send-tip-sec .send-tip-btn:focus,
.live-streaming-send-tip-sec .send-tip-btn:active {
  background: transparent !important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
}

.live-streaming-full-screen-sec {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.live-streaming-full-screen-icon {
  max-width: 2em;
}

.live-streaming-full-screen-sec .close-btn {
  color: #fff;
  border: 0;
  font-size: 1.2em;
  letter-spacing: 0.8px;
  padding: 0;
  font-weight: 500;
  border-radius: 25px;
  display: inline-block;
  background-color: transparent !important;
}

.live-streaming-full-screen-sec .close-btn:hover,
.live-streaming-full-screen-sec .close-btn:focus,
.live-streaming-full-screen-sec .close-btn:active {
  background-color: transparent !important;
  color: #fff !important;
}

.live-streaming-store-product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0;
}

.live-streaming-store-product-header h4 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.live-streaming-store-product-header a {
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 600;
}

.live-streaming-store-product-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.live-streaming-store-product-img {
  width: 100%;
  height: 22em;
  object-fit: contain;
  border-radius: 10px;
  background-color: #000;
}

.live-streaming-store-product-item {
  position: relative;
}

.live-streaming-store-product-info-card {
  justify-content: space-between;
  grid-template-columns: 110px auto;
  display: grid;
  text-align: left;
}

.live-streaming-store-product-info-sec {
  position: absolute;
  bottom: 0;
  padding: 1em;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.live-streaming-store-cart-icon-sec {
  width: 4em;
  height: 4em;
  background-color: var(--primary-color);
  color: #333;
  padding: 5px;
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.live-streaming-store-cart-icon {
  width: 1.2em;
}

.live-streaming-store-cart-icon a {
  font-size: 2em;
}

.live-streaming-store-add-cart-link {
  position: absolute;
  z-index: 3;
  right: 15px;
  top: 15px;
  width: 36px;
  padding: 0;
}

.live-streaming-store-product-details h3 {
  color: var(--secondary-color);
  word-break: break-all;
  font-size: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
}

.live-streaming-store-product-details p {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 600;
  color: var(--secondary-color);
}

.live-streaming-store-product-info-card .rating-star-sec li i {
  color: #dbff00 !important;
}

.live-streaming-stock-sec {
  background: rgba(217, 217, 217, 0.8);
  padding: 2px 0;
  text-align: center;
  position: absolute;
  bottom: 65px;
  width: 100%;
}

.live-streaming-stock-sec p {
  margin-bottom: 0;
  font-size: 1.2em;
  color: var(--tertiary-color);
  font-weight: 500;
}

.live-streaming-post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0;
}

.live-streaming-post-header h4 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.live-streaming-post-header a {
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 600;
}

.live-streaming-post-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.live-streaming-post-img {
  width: 100%;
  height: 12em;
  object-fit: cover;
  border-radius: 10px;
}

.live-streaming-post-item {
  position: relative;
}

.live-streaming-recomended-for-you-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0;
}

.live-streaming-recomended-for-you-header h4 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.live-streaming-recomended-for-you-header a {
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 600;
}

.live-streaming-recomended-for-you-box {
  grid-template-columns: repeat(7, 1fr);
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.live-streaming-recomended-for-you-img {
  width: 10em;
  height: 10em;
  object-fit: cover;
  border: 3px solid var(--primary-color);
  border-radius: 50%;
}

.live-streaming-recomended-for-you-item h5 {
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 500;
  padding-top: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
}

.live-streaming-recomended-for-you-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.live-streaming-user-list-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.live-streaming-user-list-header-sec h4 {
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 500;
}

.live-streaming-user-list-card {
  margin-bottom: 0;
  padding: 1.5em 0;
}

.live-streaming-user-list-card li a {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
}

.live-streaming-user-list-card li {
  padding-bottom: 1em;
  margin-top: 0 !important;
}

.live-streaming-user-list-card li:last-child {
  padding-bottom: 0;
}

.live-streaming-user-list-card p {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.live-streaming-user-list-img {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  object-fit: cover;
}

.live-streaming-user-comments-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.live-streaming-user-comments-header-sec h4 {
  font-size: 1.5em;
  color: var(--quaternary-color);
  font-weight: 500;
  margin-bottom: 0;
}

.live-streaming-user-comments-box {
  padding: 1.5em 0;
}

.live-streaming-user-comments-card {
  display: flex;
  align-items: center;
  gap: 1em;
  padding-bottom: 1em;
}

.live-streaming-user-comments-img {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  object-fit: cover;
}

.live-streaming-user-comments-info h5 {
  font-size: 1.5em;
  color: var(--tertiary-color);
}

.live-streaming-user-comments-info p {
  font-size: 1.3em;
  color: var(--quaternary-color);
  font-weight: 500;
  margin-bottom: 0;
}

.live-streaming-user-comments-info {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
}

.live-streaming-user-add-comments-sec {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
  /* position: fixed;
    bottom: 15px;
    right:8px;
    min-width: 380px; */
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 28%;
}

.btn-light {
  border-radius: 5px;
}

.live-streaming-user-add-comments-sec .input-group-text {
  background: transparent;
  border: 0;
  padding-left: 0;
}

.live-streaming-user-add-comments-sec .form-control {
  background: transparent !important;
  border: 0 !important;
}

.live-streaming-user-add-comments-sec .form-control:focus {
  background: transparent !important;
  border: 0 !important;
}

.live-streaming-user-add-comments-sec .form-control::placeholder {
  font-weight: 400;
  font-size: 0.9em;
}

.live-streaming-user-add-comments-icon {
  width: 3em;
}

.live-streaming-user-box ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.live-streaming-user-box ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.live-streaming-user-box ::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.live-streaming-modal-action-btn-sec {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.modal-action-btn {
  color: #fff;
  border: 0;
  font-size: 1.2em;
  letter-spacing: 0.8px;
  padding: 0 !important;
  width: 4.5em;
  height: 4.5em;
  font-weight: 500;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85) !important;
  border: 0 !important;
  justify-content: center;
}

.modal-action-btn:hover,
.modal-action-btn:focus,
.modal-action-btn:active {
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: #fff !important;
}

.modal-action-btn-icon {
  width: 2em;
  height: 2em;
  object-fit: contain;
}

.live-streaming-user-list-card-1 {
  margin-bottom: 0;
  padding: 1.5em 0;
}

.live-streaming-user-list-card-1 li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
}

.live-streaming-user-list-card-1 li {
  padding-bottom: 1em;
  margin-top: 0 !important;
}

.live-streaming-user-list-card-1 li:last-child {
  padding-bottom: 0;
}

.live-streaming-user-list-card-1 p {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.live-streaming-user-list-item {
  display: flex;
  align-items: center;
  gap: 1em;
}

.live-streaming-user-list-earning {
  font-size: 1.3em;
  color: var(--quaternary-color);
  font-weight: 500;
  margin-bottom: 0;
}

.mobile-view-comment-sec {
  display: none;
  align-items: center;
  gap: 0.5em;
}

.live-streaming-view-count-icon {
  width: 2em;
}

.live-streaming-view-count p {
  font-size: 1em;
  color: var(--quaternary-color);
  font-weight: 500;
  margin-bottom: 0;
}

.live-streaming-view-count {
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.live-streaming-comments-mobile-display .view-comments-btn {
  color: #fff;
  border: 0;
  font-size: 0.8em;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 5px;
  background-color: var(--primary-color) !important;
}

.live-streaming-comments-mobile-display .view-comments-btn:hover,
.live-streaming-comments-mobile-display .view-comments-btn:focus,
.live-streaming-comments-mobile-display .view-comments-btn:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.user-list-free-modal ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.user-list-free-modal ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.user-list-free-modal ::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}
.payment-method-sec {
  background-color: #ffffff;
  padding: 2em;
}
.wallet-balance {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.wallet-balance p {
  margin: 0;
  font-size: 1.3em;
  font-weight: 600;
}
.wallet-payment-title {
  font-size: 1.5em;
  font-weight: 600;
}
.wallet-balance-details {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.wallet-card-body {
  background-color: #f5f7f9;
}
.add-new-card-sec form .input-group-text {
  background-color: #ffffff;
  border: none;
}
.add-new-card-sec form input.form-control {
  border: none;
}
.add-new-card-sec form .input-group {
  border: 1px solid var(--primary-color);
  background-color: #ffffff;
}
.add-new-card-sec form label.form-label {
  font-size: 1.2em;
  font-weight: 400;
  margin: 0 0 0.8em;
}
.add-new-card-sec form {
  margin: 2em 0 0;
}
.card-name-text {
  border: 1px solid var(--primary-color) !important;

  background-color: #ffffff !important;
}
.add-new-card-sec form input::-webkit-input-placeholder {
  font-size: 0.8em;
}
.add-card-btn button {
  color: #fff;
  border: 0;
  font-size: 1.2em;
  padding: 1em;
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--primary-color) !important;
  width: 70%;
}
.add-card-btn button:hover,
.add-card-btn button:focus,
.add-card-btn button:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.add-new-card-sec form .input-group .form-control:focus {
  border-bottom: none !important;
}
.card-name-text:focus {
  border: 1px solid var(--primary-color) !important;
}
.tips-box {
  border: 1px solid var(--primary-color);
  padding: 7px 10px;
  font-size: 1em;
  color: var(--secondary-color);
  font-weight: 500;
  background: var(--primary-color);
  border-radius: 5px;
}
.wallet-modal-details form label {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  border-radius: 7px !important;
}

.wallet-modal-details form input[type="radio"] {
  display: none;
}
.tips-list .btn-group {
  gap: 1em;
}

.wallet-modal-details form .btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}
.wallet-modal-details input.form-control {
  border-top: 1px solid var(--primary-color) !important;
  border-bottom: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color) !important;
  border-right: 1px solid var(--primary-color) !important;
}
.payment-modal-title {
  font-weight: 700;
  font-size: 1.5em;
  margin: 0 0 0.5em;
}
.wallet-modal-details p {
  color: #9896a1;
  font-size: 1.3em;
  margin: 0 0 2em;
}
.tips-list {
  margin: 0 0 2em;
}
.wallet-account-balance {
  margin: 0 0 1em;
  font-weight: 700;
  font-size: 2em;
}
.wallet-grid-card .card {
  justify-content: space-between;
  flex-direction: initial;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  padding: 1.5em;
  border-radius: 10px;
  margin-top: 2em;
  align-items: center;
}
.paypal-account-details h5,
.paypal-account-details p {
  margin: 0;
}
.paypal-account-details {
  display: flex;
  align-items: center;
  gap: 0.3em;
  flex-direction: column;
}
.payment-method-sec .wallet-grid-card .card .radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border: 1px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  height: 2em;
  outline: none;
  width: 2em;
  border: 1px solid #ddd;
}
.payment-method-sec .wallet-grid-card .card .radio::after {
  border: 1px solid #fff;
  border-top: 0;
  border-left: 0;
  content: "";
  display: block;
  height: 1rem;
  left: 26%;
  position: absolute;
  top: 43%;
  transform: rotate(45deg) translate(-50%, -50%);
  width: 0.375rem;
}
.payment-method-sec .wallet-grid-card .card .radio:checked {
  background: #f0c433;
  border-color: transparent;
}
.payment-multiple-card {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  padding: 1.5em;
  border-radius: 10px;
  margin-top: 2em;
}
.payment-multiple-box {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0em;
  column-gap: normal;
}
.payment-multiple-box .card {
  border: 1px solid var(--primary-color);
  flex-direction: initial;
  padding: 1em;
  width: 98%;
  justify-content: space-between;
}
.payment-card-details h5,
.payment-card-details p {
  margin: 0;
}
.payment-card-details {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.payment-card-details img {
  max-width: 4em;
}
.payment-multiple-box .card .radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border: 1px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  height: 2em;
  outline: none;
  width: 2em;
  border: 1px solid #ddd;
}
.payment-multiple-box .card .radio::after {
  border: 1px solid #fff;
  border-top: 0;
  border-left: 0;
  content: "";
  display: block;
  height: 1rem;
  left: 26%;
  position: absolute;
  top: 43%;
  transform: rotate(45deg) translate(-50%, -50%);
  width: 0.375rem;
}
.payment-multiple-box .card .radio:checked {
  background: #f0c433;
  border-color: transparent;
}
.payment-card-details p {
  color: var(--quaternary-color);
}
.add-card button {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: none;
  font-size: 1.3em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.add-card button:hover {
  color: var(--primary-color);
}
.add-card button span img {
  width: 1em;
}
.add-card button:active,
.add-card button:focus {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
  box-shadow: none;
}
.paypal-account-details p {
  color: var(--quaternary-color);
}
@media (max-width: 767.98px) {
  .payment-multiple-box {
    grid-template-columns: 100%;
    gap: 1em;
  }
  .payment-multiple-box .card {
    width: 100%;
  }
  .add-new-card-sec {
    padding: 2em;
  }
  .wallet-modal-details {
    padding: 2em;
  }
}
@media (max-width: 991.98px) {
  .wallet-modal-details {
    padding: 2em;
  }
  .add-new-card-sec {
    padding: 2em;
  }
}

.live-video-call-chat-room .no-data-found-sec .no-data-found-img-sec img {
  max-width: 20em;
}

.live-streaming-user-add-comments-sec .icon-btn.btn.btn-light:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.live-streaming-user-add-comments-sec .icon-btn.btn.btn-light:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.live-streaming-user-add-comments-sec .icon-btn.btn.btn-light:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.new-comments-form .input-group {
  justify-content: space-between;
}

.most-popular-user-btn-sec .join-now-btn.hoverColor:hover {
  background-color: var(--button-hover-color) !important;
}

.live-streaming-send-tip-sec .send-tip-btn.hoverColor:hover {
  background-color: var(--button-hover-color) !important;
}

.new-go-live-btn:active {
  color: var(--secondary-color) !important;
  outline: none !important;
}
