.video-audio-call-request-sec{
    margin-top: 85px;
    margin-bottom: 4em;
}

.video-audio-call-request-tabs-card{
    background-color: var(--secondary-color);
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    /* box-shadow: 0 2px 5px rgba(0,0,0,.05); */
    background-color: var(--secondary-color);
    box-shadow: 0px 0px 7.20117px rgba(0, 0, 0, 0.1);
    padding: 2em;
}

.video-audio-call-request-tabs-card .nav-pills .nav-link.active {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-bottom: 1px solid var(--primary-color);
}

.video-audio-call-request-tabs-card .nav-pills .nav-link.active:hover,
.video-audio-call-request-tabs-card .nav-pills .nav-link.active:focus{
    color: var(--secondary-color)!important;
    outline: none;
}

.video-audio-call-request-tabs-card .nav-pills .nav-link:hover,
.video-audio-call-request-tabs-card .nav-pills .nav-link:focus{
    color: #222222!important;
    outline: none;
}

.video-audio-call-request-tabs-card .nav-pills .nav-link{
    background-color: #eeeeee;
    color: #222222;
    border-radius:25px;
    font-weight: 600;
    font-size: 1.2em;
    padding:1em 2em;
    margin-right: 1em;
    border-bottom: 1px solid #eeeeee;
    text-transform: uppercase;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content tr{
    background-color:#F5F7FB;
}

.bg-white{
    background-color: var(--secondary-color);
}

.video-audio-call-request-tabs-content{
    padding: 2em 0;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content td {
    font-size: 1.4em;
    font-weight: 500;
    vertical-align: middle;
    white-space: nowrap;
    color:var(--tertiary-color);
    border-top: 0;
    padding:1.5em;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content td:first-child{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content td:last-child{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content th:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content th:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.video-audio-call-request-tabs-card table {
    border-collapse: separate;
    border-spacing: 0 1em;
}

.video-audio-call-request-tabs-card .video-audio-call-request-tabs-content th {
    font-size: 1.4em;
    font-weight: 500;
    color: var(--secondary-color);
    /* border-bottom: 1px solid var(--primary-color); */
    border: 0;
    white-space: nowrap;
    padding:1.5em;
    background-color: var(--primary-color);
}

.video-audio-call-request-tabs-card .nav-tabs{
    border-bottom: 1px solid var(--primary-color);
}

.video-audio-call-request-tabs-card h2{
    font-size: 1.5em;
    font-weight: 600;
    color: #222222;
    margin-bottom: 1em;
}

.video-audio-call-request-tabs-card .fa-times-circle {
    color: #FF0000;
}

.video-audio-call-request-tabs-card .fa-check-circle{
    color: #008000;
}

.video-audio-call-request-tabs-card .fa-info-circle{
    color: #F24E1E;
}

.video-audio-call-request-tabs-card .tick{
    color: var(--primary-color);
}

.video-audio-call-request-tabs-card .payment-tabs-content p{
    margin-bottom: 0;
}

.join-decline-btn-sec{
    display: flex;
    align-items: center;
    gap: 1em;
}

.join-decline-btn-sec i{
    font-size: 2em;
}

.join-decline-btn-sec .table-btn-icon{
    border: 0 !important;
    background: transparent !important;
    padding: 0px 0 !important;
    color: #000;
  }
  
  .join-decline-btn-sec .table-btn-icon:hover,
  .join-decline-btn-sec .table-btn-icon:focus,
  .join-decline-btn-sec .table-btn-icon:active {
    border: 0 !important;
    background: transparent !important;
    color: #000!important;
  }

  .join-decline-btn-sec .table-btn-icon.payment-pending{
    border: 0 !important;
    background: #F24E1E !important;
    padding: 0.8rem 1.5rem!important;
    color: var(--secondary-color);
    font-size: 0.8em!important;
    font-weight: 500!important;
    border-radius: 25px!important;
  }
  
  .join-decline-btn-sec .table-btn-icon.payment-pending:hover,
  .join-decline-btn-sec .table-btn-icon.payment-pending:focus,
  .join-decline-btn-sec .table-btn-icon.payment-pending:active {
    border: 0 !important;
    background: #F24E1E !important;
    color: var(--secondary-color)!important;
  }

  .join-decline-btn-sec .table-btn-icon.start-call{
    border: 0 !important;
    background: var(--primary-color) !important;
    padding: 0.8rem 1.5rem!important;
    color: var(--secondary-color);
    font-size: 0.8em!important;
    font-weight: 500!important;
    border-radius: 25px!important;
  }
  
  .join-decline-btn-sec .table-btn-icon.start-call:hover,
  .join-decline-btn-sec .table-btn-icon.start-call:focus,
  .join-decline-btn-sec .table-btn-icon.start-call:active {
    border: 0 !important;
    background: var(--primary-color) !important;
    color: var(--secondary-color)!important;
  }

  .video-audio-call-request-tabs-card ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }
  
  .video-audio-call-request-tabs-card ::-webkit-scrollbar {
    width: 6px;
    height:4px;
    background-color: #F5F5F5;
  }
  
  .video-audio-call-request-tabs-card ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
  }

  .video-audio-call-request-tabs-content .table-responsive{
    border: 0!important;
  }
  
