.user-chat-sec {
    margin-top: 80px;
    margin-bottom: 4em;
}

.user-chat-box {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* box-shadow: 0 0 10px 4px rgb(31 66 135 / 10%); */
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    border-radius: 15px;
}

.user-chat-list-sec {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
    position: relative;
    width: 100%;
    padding: 2em;
}

.user-chat-room-sec {
    -webkit-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
    position: relative;
    width: 100%;
    background-color: #f9fafc;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 2em 0;
}

.user-chat-list-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1.5em 0;
    height: 8em;
}

.user-chat-list-header .back-icon i {
    width: 3em;
    height: 3em;
    background-color: #edf0f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b0b2b5;
}

.user-chat-list-header h3 {
    padding-left: 1em;
    margin-bottom: 0;
    font-size: 2em;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.chat-list-search-sec {
    padding-top: 2em;
}

.chat-list-search-sec .form-control::placeholder {
    font-size: 0.9em;
    font-weight: 500;
}

.chat-list-search-sec .form-control {
    border: 1px solid #ddd !important;
    background-color: #f9fafc !important;
    border-right: 0 !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    padding: 1em !important;
}

.chat-list-search-sec .input-group-text {
    border: 1px solid #ddd !important;
    background-color: transparent !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-size: 14px !important;
    border-left: 0 !important;
    padding: 0 1em;
}

.user-list-card .user-list-img-sec .user-list-img {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    object-fit: cover;
}

.user-list-card {
    display: flex;
    justify-content: space-between;
    padding: 1.5em;
    margin-top: 2em;
}

.user-list-card.active {
    background-color: #f9fafc;
    border-radius: 10px;
}

.user-list-card .user-list-info h6 {
    font-size: 1.4em;
    font-weight: 600;
    margin-bottom: 0.2em;
}

.user-list-card .user-list-info p {
    font-size: 1em;
    font-weight: 500;
    color: #999999;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8em;
    -webkit-box-orient: vertical;
}

.user-list-card .user-list-time p {
    font-size: 1em;
    font-weight: 500;
    color: #999999;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-orient: vertical;
}

.user-list-card .user-list-body {
    display: flex;
}

.user-list-card .user-list-body .user-list-info {
    padding-left: 1.5em;
}

.chat-list-collapse-body {
    height: 60vh;
    overflow-y: auto;
}

.h-dots {
    color: var(--senary-color);
    font-size: 1.8em;
}

.link-user-name {
    padding-left: 1.35em;
    font-size: 15px;
}

/* .user-chat-room-header .r-dropdown-menu{
    transform: translate(-12px, 33px)!important;
} */

.user-chat-room-sec .user-chat-room-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 1.5em;
    padding-bottom: 2em;
    justify-content: space-between;
    padding-right: 1em;
}

.user-chat-room-sec .user-chat-room-header h3 {
    padding-left: 1em;
    margin-bottom: 0;
    font-size: 2em;
    font-weight: 600;
    letter-spacing: 0.5px;
}

/* .user-message-content-sec{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
} */

.user-chat-main-wrapper-body {
    padding: 2em 1em;
    height: 60vh;
    overflow-y: auto;
}

.user-chat-main-wrapper-body .user-message-right-align {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    text-align: right;
}

.user-message-right-align .user-message-user-img-sec {
    width: 3em;
    position: relative;
    object-fit: cover;
}

.user-message-user-img-sec .user-message-img {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    object-fit: cover;
}

.user-message-right-align .user-message-info {
    padding-right: 1em;
}

.user-message-right-align .user-message-info h6 {
    background-color: #007bff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 10px;
    color: #ffffff;
    width: fit-content;

    /* width: 57em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 20;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 20em; */
}

.user-message-info h6 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.2em;
    background-color: #ffffff;
    padding: 1em 1.5em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    line-height: 1.5;
    word-break: break-all;
}

.user-message-info p {
    font-size: 1em;
    font-weight: 500;
    color: #999999;
    padding-left: 1em;
    padding-top: 0.5em;
}

.user-message-left-align {
    display: flex;
    align-items: flex-end;
}

.user-message-left-align .user-message-info {
    padding-left: 1em;
}

.user-message-left-align .user-message-info h6 {
    background-color: #fff;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 0px;
    color: #212529;
}

.typing-text {
    padding-left: 4em;
}

.user-chat-main-wrapper-footer .form-control::placeholder {
    font-size: 0.9em;
    font-weight: 500;
}

.user-chat-main-wrapper-footer .form-control {
    border: 1px solid #ddd !important;
    background-color: #ffffff;
    border-right: 0 !important;
    font-size: 1.1em;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    font-weight: 600;
    color: #999999;
    padding: 1.6em !important;
}

.user-chat-main-wrapper-footer .input-group-text {
    border: 1px solid #ddd !important;
    border-left: 0 !important;
    background-color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 14px !important;
    padding: 0em 1em;
}

.user-chat-main-wrapper-footer .input-group-text button {
    padding: 1em;
}

.user-chat-main-wrapper-footer .input-group-text button i {
    /* background: #007bff; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #fff;
    font-size: 1.5em;
}

.user-chat-main-wrapper-footer .input-group-text button:hover {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.user-chat-main-wrapper-footer {
    padding: 0 1em;
}


.user-chat-sec ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px
}

/* background of the scrollbar except button or resizer */
.user-chat-sec ::-webkit-scrollbar-track {
    background-color: #fff
}

.user-chat-sec ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4
}

/* scrollbar itself */
.user-chat-sec ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff
}

.user-chat-sec ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.user-chat-sec ::-webkit-scrollbar-button {
    display: none
}

.user-chat-list-header a {
    font-size: inherit;
}

.user-chat-msg {
    font-size: inherit;
    color: black;
}

#chat-invalid .tooltip-inner {
    font-size: 1.5em;
    padding: 0.5em 1em;
    background-color: #dc3545;
    color: #fff;
    font-weight: 500;
}

#chat-invalid .arrow::before {
    border-top-color: #dc3545;
}

.chat-room-audio-display {
    height: 100px !important;
    width: 250px !important;
}

.user-chat-room-sec .user-chat-room-header p {
    padding-left: 1.5em;
    margin-bottom: 0;
    color: grey;
    font-size: 13px;
}

.input-group-append .chat-border-zero {
    border-radius: 0 !important;
    border-right: 0 !important;
    padding-right: 0 !important;
}

.chat-icon-sec {
    display: flex;
}

.border-radius-zero {
    border-radius: 0 !important;
}

.user-chat-mobile-room {
    display: none;
}

.user-chat-main-wrapper-body .react-fancybox .box {
    background-color: rgba(0, 0, 0, 0.8);
    transform: translate3d(0, 30px, 0);
    z-index: 3;
}

.user-chat-main-wrapper-body .react-fancybox .box::before {
    display: none;
}

.user-chat-main-wrapper-body .react-fancybox .box img {
    width: unset;
    max-width: unset;
    transition: unset;
    transform: unset;
    height: unset;
    object-fit: unset;
}

.user-chat-main-wrapper-body .react-fancybox .close-btn {
    width: 3em !important;
    z-index: 3;
}